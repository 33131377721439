.primary,
.secondary {
  border-color: transparent;
}

.primary {
  background-color: var(--background-brand-primary2);
}

.secondary {
  background-color: var(--background-brand-secondary2);
}
.accordionSection {
  width: 100%;
}

.gridContainer {
  padding-top: 1rem;
  width: 100%;
}

.titleContainer {
  grid-column: span 4;
}

.contentContainer {
  grid-column: span 7;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.accordion {
  width: 100%;
}

@media (min-width: 768px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 24px;
    padding-top: 1.5rem;
  }

  .contentContainer {
    margin-top: 0;
  }
}
