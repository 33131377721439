.button {
  --text-color: var(--text-primary);
  --background-color: transparent;
  --border-color: transparent;
  --border: 2px solid var(--border-color);
  --width: 100%;
  --opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  min-height: 48px;
  border-radius: var(--border-radius-button);
  color: var(--text-color);
  background-color: var(--background-color);
  border: var(--border);
  width: var(--width);
  opacity: var(--opacity);
  text-wrap: nowrap;
  letter-spacing: 0.48px;
}

.button:hover:enabled {
  --opacity: 0.8; /* Change opacity on hover */
}

.button:active:enabled {
  --opacity: 0.6; /* Change opacity on active */
}

.primary {
  --background-color: var(--primary);
}

.primary:disabled {
  --background-color: var(--button-background-disabled-primary);
}

.secondary {
  --background-color: white;
  --border-color: var(--black999);
}

.secondary:disabled {
  --border-color: var(--buttonStrokeDisabled);
  --background-color: var(--buttonBackgroundDisabledSecondary);
  --text-color: var(--buttonTextDisabledSecondary);
}

.tertiary:disabled {
  --text-color: var(--button-text-disabled-secondary);
}

.tertiary:hover:enabled {
  --opacity: 0.6;
}

.tertiary:active:enabled {
  --opacity: 1;
  --background-color: var(--button-pressed-secondary);
}

.fourth {
  --background-color: var(--button-background-sixth);
  --text-color: var(--buttonTextTertiary);
}

.fourth:hover:enabled {
  --opacity: 0.8;
}

.fourth:active {
  --opacity: 0.6;
}

.fourth:disabled {
  --background-color: var(--button-background-disabled-tertiary);
  --text-color: var(--button-text-disabled-primary);
}

.big {
  padding: var(--padding-large) var(--padding-xlarge);
}

.small {
  padding: var(--padding-small) var(--padding-large);
}

.marginLeft {
  margin-left: 0.5rem;
}
