.dropdownContainer {
  position: relative;
  cursor: pointer;
}

.label {
  /* TODO: Move to a common place */
  /* composes: label from '../OnboardingTextField/OnboardingTextField.module.css'; */
  composes: label from '../../../../components/OnboardingTextField/OnboardingTextField.module.css';
  position: relative;
}

.dropdownButton {
  background-color: transparent;
  width: 100%;
  border-bottom: 2px solid black;
  cursor: pointer;
  font-size: var(--input-font-size);
}

.dropdownIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dropdownContent {
  position: absolute;
  z-index: 1;
  border-radius: var(--border-radius-zero, 0px);
  border: 2px solid var(--stroke-secondary, #000);
  border-top: none;
  background: var(--background-primary, #fff);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  cursor: pointer;
}

.dropdownOption {
  color: var(--button-text-secondary, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: var(--lineheight-utton, 24px); /* 150% */
  letter-spacing: 0.48px;
  padding: var(--padding-tiny, 8px);
}

.selected {
  background: var(--background-brand-primary2);
}

.dropdownOption:hover {
  background: var(--background-tertiary);
}
