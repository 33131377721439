.container {
  min-height: calc(100vh - var(--top-bar-height));
  background-color: var(--background-primary);
  margin-bottom: 0.125rem;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: var(--padding-block-vertical-secondary)
    var(--padding-block-horizontal);
}
