.datepickerHeader {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.datepickerControls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: capitalize;
}

.datepickerControls > * + * {
  margin-left: 1.5rem;
}

.datepickerButton {
  height: 2rem;
  width: 2rem;
}

.datepickerButtonPrev {
  transform: rotate(90deg);
}

.datepickerButtonNext {
  transform: rotate(-90deg);
}

.datepickerButton:disabled {
  opacity: 0.2;
}

.datepickerButton:disabled:hover,
.datepickerButton:disabled:active {
  opacity: 0.2;
}
