.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  text-align: center;
  margin-top: 1rem;
}

.linksDescription {
  color: var(--text-secondary);
  margin-top: 1.5rem;
}

.linksContainer {
  display: flex;
  gap: 0.25rem;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.link {
  color: var(--text-secondary);
}
