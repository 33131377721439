.blockContent {
  text-align: center;
  width: 100%;
  max-width: 676px;
  display: flex;
  flex-direction: column;
  gap: var(--gap-set-giga);
}

.changeDetailsButton {
  width: auto;
  align-self: flex-end;
  padding: var(--padding-tiny);
}

.inputs {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-set-medium);
  align-self: stretch;

  & > div {
    flex: 1 0 0;
  }
}

@media (min-width: 1440px) {
  .block {
    padding: var(--padding-block-vertical-secondary)
      var(--padding-block-horizontal);
  }
}
