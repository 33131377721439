.contentContainer {
  padding-top: 1rem;
}

.documentBlock {
  background-color: var(--background-brand-secondary2);
  border-radius: var(--border-radius-tertiary);
  padding: var(--padding-large);
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--text-primary);
}

.documentBlockTitle {
  font-size: var(--font-body-big);
  font-weight: 600;
}

.documentBlockContainer {
  display: grid;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

@media (min-width: 768px) {
  .documentBlockContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'first second'
      'third third';
  }

  .documentBlockContainer > *:nth-child(1) {
    grid-area: first;
  }
  .documentBlockContainer > *:nth-child(2) {
    grid-area: second;
  }
  .documentBlockContainer > *:nth-child(3) {
    grid-area: third;
  }
}

@media (min-width: 1024px) {
  .documentBlockContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'first second third';
  }
}

@media (min-width: 1440px) {
  .contentContainer {
    padding-top: 1.5rem;
  }
}
