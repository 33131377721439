.textInput {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.input {
  background: transparent;
  position: relative;
  border-bottom: 0.125rem solid var(--input-stroke-primary, #000);
  outline: none;
  font-size: var(--input-font-size);
  width: 100%;
}

.input::placeholder {
  color: transparent;
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
  transform: translateY(-100%);
}

.label {
  color: var(--input-text-secondary, #5a5d65);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  left: 0;
  letter-spacing: 0.045rem;
  line-height: 1rem;
  margin-bottom: 0.3125rem;
  position: absolute;
  text-transform: uppercase;
  transform-origin: left center;
  transition: transform 250ms;
}

.textInput [role='status'] {
  position: absolute;
  right: 0;
}

.loader {
  color: var(--background-brand-primary1);
}

.input:disabled {
  background: transparent;
  border-bottom: 2px solid var(--input-stroke-disabled);
  cursor: not-allowed;
}

.input:disabled + .label {
  color: var(--input-text-disabled);
}
