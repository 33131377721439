.inputMessageBlock {
  border-radius: var(--border-radius-secondary);
  margin-top: 1rem;
  padding: var(--padding-card-primary) !important;
  background: var(--background-brand-fourth2);
}

.inputMessage {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1.5rem;
}

.textArea {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
