.processStep {
  align-items: flex-start;
  background: var(--background-primary);
  border: 0.125rem solid var(--stroke-secondary);
  border-radius: var(--border-radius-primary);
  display: flex;
  flex-direction: column;
  padding: var(--padding-set-large);
  width: 100%;
}

.processStep > * {
  width: 100%;
}

.processStepHeader {
  display: flex;
  align-items: center;
  width: 100%;
}

.processHeaderContainer {
  margin-bottom: var(--gap-set-large);
}

.stepNumber {
  --stepNumberBackground: var(--background-tertiary);
  background: var(--stepNumberBackground);
  border-radius: 50%;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  padding: var(--padding-tiny);
  width: 2.5rem;
}

.unprocessed {
  --stepNumberBackground: var(--background-tertiary);
}

.success {
  --stepNumberBackground: var(--background-brand-secondary2);
}

.error {
  --stepNumberBackground: var(--error-background);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.description {
  margin-top: 0.5rem;
  color: var(--text-secondary);
}
