.yourPrice {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textCommon {
  font-style: normal;
  font-size: var(--font-body-big, 24px);
  line-height: 120%;
  letter-spacing: 0.24px;
}

.priceBlock {
  margin-top: 8px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  align-self: stretch;
}

.monthlyPrice {
  line-height: 110%;
}

.container {
  height: 90%;
}

.container > * + * {
  margin-top: 0.125rem;
}

.priceSuggestionBlock {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
