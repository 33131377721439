.accordion {
  border-radius: var(--border-radius-tertiary);
  border: 2px solid;
  transition: all 0.2s ease;
}

.accordionButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-set-large) var(--padding-card-secondary);
}

.accordionButton:not(:disabled) {
  cursor: pointer;
}

.accordionTitle {
  font-size: var(--font-body-big);
  font-weight: 600;
  text-align: left;
}

.icon {
  height: 2rem;
  width: 2rem;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.accordionContent {
  overflow: hidden;
  padding: 0 var(--padding-card-secondary);
  transition: height 0.3s ease-in-out;
}

.contentWrapper {
  padding-bottom: var(--padding-set-large);
  font-size: var(--font-body-medium);
  font-weight: 500;
}

.subtitle {
  text-align: left;
  font-size: var(--font-body-medium);
}

.listItem {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.checkIcon {
  margin-right: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;
}

.listText {
  text-align: left;
  font-size: var(--font-body-medium);
}

.contentText {
  white-space: pre-line;
  text-align: left;
  font-size: var(--font-body-big);
}
