.block {
  padding: 0.625rem var(--padding-block-horizontal);

  position: absolute;
  top: var(--top-bar-height);
  left: 0;
  right: 0;
  height: auto;
  z-index: 1000;

  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.blockHidden {
  opacity: 0;
}

.blockInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bankIdButton {
  width: 12.5rem;
  height: 48px;
}

.startDateContainer {
  min-width: 20.5rem;
}

.selectedDate {
  font-size: var(--font-body-medium);
}

.calendarIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.datePickerContainer {
  display: none;
}

.calendar {
  --day-width: 3rem;
  --day-width-mobile: var(--day-width);
  --day-width-tablet: var(--day-width);
  --day-width-desktop: var(--day-width);
}

@media (min-width: 768px) {
  .datePickerContainer {
    display: block;
  }
}

@media (min-width: 1440px) {
  .startDateContainer {
    flex-direction: row;
    align-items: center;
  }

  .bankIdButton {
    min-height: 2.5rem;
  }
}
