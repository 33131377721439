.container {
  --radio-checked-color: var(--input-stroke-primary);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
  padding: 0 1rem;
  border-radius: var(--border-radius-card-small);
  background: transparent;
  align-items: center;
}

.container input[type='radio'] {
  box-sizing: border-box;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.375rem;
  padding: 0;
  border: 0.125rem solid var(--input-stroke-primary);
  border-radius: 50%;
  appearance: none;
  background-color: transparent;
  outline: none;
}

.container:has(input[type='radio']:checked) {
  background-color: var(--background-brand-fourth1);
}

.container input[type='radio']:not(:disabled):checked {
  border-color: var(--radio-checked-color);
  background-color: var(--radio-checked-color);
  background-clip: content-box;
  padding: 0.125rem;
  background-image: radial-gradient(
    circle,
    var(--radio-checked-color) 0%,
    var(--radio-checked-color) 50%,
    transparent 60%,
    transparent 100%
  );
}

.label {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: var(--line-height-button);
  letter-spacing: 0.03rem;
}

.radioButton {
  display: flex;
  align-items: center;
  gap: 0.125rem;
}

.icons {
  display: flex;
  gap: 0.125rem;
}
