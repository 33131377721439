.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0rem;
}

.iconButton {
  background-color: var(--button-background-fifth) !important;
  flex-shrink: 0;
}

.title {
  font-size: var(--font-body-medium);
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.2px;
  margin-bottom: 0.25rem;
}

.description {
  font-size: var(--font-body-tiny);
  line-height: 19.6px;
  letter-spacing: 0.14px;
  color: var(--text-secondary) !important;
}
