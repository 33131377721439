.container {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .container {
    max-width: 40.25rem;
  }
}
