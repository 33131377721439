.quoteResultDisplay {
  display: flex;
  padding: var(--padding-block-vertical-primary) var(--padding-block-horizontal);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-set-medium);
  align-self: stretch;
  width: 100%;
  border-radius: var(--border-radius-secondary);
  background-color: var(--background-brand-fourth3);
}

.firstPremiumAmount {
  font-size: var(--font-body-big);
  font-weight: 600;
  line-height: 116%;
  letter-spacing: 0.12px;
}

.priceContainer {
  align-items: baseline;
  display: flex;
  gap: 0.5rem;
}

.premiumPrice {
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.4px;
  text-decoration: line-through;
}

.firstYearlyPaymentRow {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.firstYearlyPaymentAmountRow {
  margin-top: 0.25rem;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .yearlPaymentContainer {
    display: flex;
    justify-content: space-between;
  }
  .firstYearlyPaymentAmountRow {
    margin-top: 0;
  }
}
