.linkLabel {
  text-decoration: none;
  color: var(--button-text-secondary);
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.column a {
  text-decoration: none;
}

.column a:hover {
  text-decoration: underline;
}
