.block {
  background-color: var(--background-brand-primary2);
}

.grid {
  display: grid;
  gap: 2rem;
  margin: 0 auto;
  background-color: var(--background-brand-primary2);
  padding: var(--padding-large) 0rem;

  /* Mobile: 2 columns */
  grid-template-columns: repeat(2, 1fr);
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.org {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.sejfaLogo {
  width: 4.25rem;
  order: -9999;
}

/* Tablet: 3 columns */
@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .org {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .sejfaLogo {
    order: 0;
  }
}
