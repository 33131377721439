.summaryPage {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

/* TODO: Should be reused between components */
/* This is used, but only in composing styles together */
.blockContent {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: var(--gap-set-giga);
}

.displayContainer {
  display: flex;
  padding: var(--padding-block-vertical-primary) var(--padding-block-horizontal);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-set-medium);
  width: 100%;
  border-radius: var(--border-radius-secondary);
}

.formDataDisplay {
  composes: displayContainer;
  background: var(--background-tertiary);
}

.formDataRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.loadingContainer {
  display: grid;
  height: 100%;
  width: 100vw;
  place-content: center;
  color: white;
}

.centerText {
  text-align: center;
}

.block {
  padding: var(--padding-block-vertical-secondary)
    var(--padding-block-horizontal);
}

@media (min-width: 1024px) {
  .block {
    padding: var(--padding-block-vertical-secondary)
      var(--padding-block-horizontal) !important;
  }
}

@media (min-width: 1440px) {
  .block {
    padding: var(--padding-block-vertical-secondary)
      var(--padding-block-horizontal) !important;
  }
}

.getInsuranceButton {
  margin-top: var(--gap-set-medium);
}
