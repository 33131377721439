@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: inherit !important;
  border-radius: var(--border-radius-secondary) !important;
  overflow: hidden !important;
  border: 0px !important;
}

.react-datepicker__day-name {
  text-transform: capitalize !important;
  margin-bottom: 0rem !important;
  font-weight: 500 !important;
  color: #5a5d65 !important;
}

.react-datepicker__day--selected {
  font-weight: bold !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0 !important;
  color: black !important;
}

.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker__header {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 1rem 0rem 0rem 0rem !important;
  margin-bottom: 0rem !important;
  width: 100%;
}

.react-datepicker__month {
  margin-bottom: 1rem !important;
}

:root {
  --black100: #fff;
  --black200: #ebedf0;
  --black600: #8b8f9a;
  --black800: #5a5d65;
  --black999: #000;
  --blue: #5e8cf4;
  --purple250: #d4bbf2;
  --purple500: #c9a9ef;
  --purple600: #aa77e5;
  --purple700: #7753a0;
  --green400: #caff86;
  --red-500: #ea4c5f;
  --red-700: #d3384a;

  --link: var(--purple700);
  --top-bar-height: 5rem;
  --input-font-size: 20px;
  --input-stroke-primary: #000;
  --input-text-secondary: var(--black800);
  --input-text-error: var(--red-700);
  --input-text-disabled: var(--black600);
  --input-stroke-error: var(--red-500);
  --input-stroke-disabled: var(--black200);

  --border-radius-zero: 0rem;
  --border-radius-primary: 1rem;
  --border-radius-secondary: 0.5rem;
  --border-radius-tertiary: 0.75rem;
  --border-radius-button: 4rem;
  --border-radius-card-small: 1rem;
  --border-radius-input-chat: 2rem;
  --stroke-secondary: var(--black999);
  --line-height-button: 1.5rem;

  --background-primary: var(--black100);
  --background-tertiary: var(--black200);
  --error-background: #ffa1c1;

  --padding-xlarge: 2rem;
  --padding-large: 1.5rem;
  --padding-medium: 1rem;
  --padding-small: 0.75rem;
  --padding-tiny: 0.5rem;
  --padding-block-vertical-primary: 16px;
  --padding-block-vertical-secondary: 2.5rem;
  --padding-block-horizontal: 1rem;
  --padding-set-giga: 2rem;
  --padding-set-large: 1.5rem;
  --padding-set-medium: 1rem;
  --padding-set-small: 0.75rem;
  --padding-card-primary: 0.75rem;
  --padding-card-secondary: 0.75rem;

  --background-brand-primary1: var(--purple600);
  --background-brand-primary2: var(--purple250);
  --background-brand-secondary2: var(--green400);
  --background-brand-fourth1: var(--blue);
  --background-brand-fourth2: #a0c6ff;
  --background-brand-fourth3: #85b5fd;
  /* TODO: These colors cannot be found in figma, they might be new? 👆 */

  --breakpoints-max-width-centeredRow: 676px;
  --font-body-big: 1.5rem;
  --font-body-medium: 1.25rem;
  --font-body-small: 1rem;
  --font-body-tiny: 0.875rem;
  --font-header-four-size: 2.5rem;
  --gap-set-medium: 1rem;
  --gap-set-large: 1.5rem;
  --gap-set-giga: 2rem;

  --button-disabled-primary: var(--black200);
  --button-text-disabled-primary: var(--black800);
  --button-background-disabled-primary: var(--black200);
  --button-background-disabled-tertiary: rgba(0, 0, 0, 0.08);
  --button-background-sixth: var(--black999);
  --button-background-fifth: var(--blue);
  --button-text-secondary: var(--black999);
  --button-text-tertiary: var(--black100);
  --button-text-disabled-secondary: var(--black600);
  --button-pressed-secondary: var(--black200);
  --button-pressed-primary: var(--purple250);
  --button-stroke-primary: var(--black100);

  --picker-onboarding: var(--blue);
  --picker-footer: var(--purple500);

  --text-primary: var(--black999);
  --text-secondary: var(--black800);

  /* Tailwind translations */
  /* TODO: Do we need these still? */
  --primary: #aa77e5;
  --text-primary: var(--black999);
  --buttonStrokeDisabled: var(--black600);
  --buttonBackgroundDisabledSecondary: var(--black100);
  --buttonTextDisabledSecondary: var(--black600);
  --buttonTextTertiary: var(--black100);
  --buttonTextPrimary: var(--black999);
  --pressedPrimary: var(--purple250);
  --disabled: var(--black200);
}

.ot-floating-button__front {
  background-color: #aa77e5;
}

.ot-floating-button__back {
  background-color: #aa77e5;
}

#ot-sdk-btn-floating .ot-floating-button__back {
  background-color: #aa77e5 !important;
}

#ot-sdk-btn-floating .ot-floating-button__front.custom-persistent-icon {
  background-color: #fff !important;
}

#onetrust-accept-btn-handler {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.ot-floating-button__close {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

#ot-sdk-btn-floating .ot-floating-button__front,
#ot-sdk-btn-floating .ot-floating-button__back {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

#accept-recommended-btn-handler {
  border-radius: 64px !important;
  color: #000 !important;
}

#onetrust-reject-all-handler {
  background-color: #aa77e5 !important;
}

.save-preference-btn-handler .onetrust-close-btn-handler {
  border-radius: 64px !important;
  color: #000 !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  background-color: #fff !important;
}

#onetrust-pc-sdk .ot-tgl input:unchecked + .ot-switch .ot-switch-nob {
  background-color: #ebedf0 !important;
}

#onetrust-pc-sdk .ot-switch-nob:before {
  background-color: #c9ccd3 !important;
}
#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob:before {
  background-color: #aa77e5 !important;
}
#onetrust-consent-sdk
  #onetrust-pc-sdk
  button:not(#clear-filters-handler):not(.ot-close-icon):not(
    #filter-btn-handler
  ):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not(
    [aria-expanded]
  ):not(.ot-link-btn),
#onetrust-consent-sdk
  #onetrust-pc-sdk
  .ot-leg-btn-container
  .ot-active-leg-btn {
  border-radius: 64px;
  color: #000 !important;
}

.ot-pc-refuse-all-handler {
  border-radius: 64px !important;
  color: #000 !important;
}

.privacy-notice-link {
  color: #7753a0 !important;
}

.ot-always-active {
  color: #7753a0 !important;
}

#ot-sdk-btn-floating.ot-floating-button {
  bottom: 16px !important;
  left: 16px !important;
  z-index: 10 !important;
}

.ot-floating-button__front {
  background-image: url('/settings.png') !important;
}

#front-button-container-0 {
  bottom: 16px !important;
  right: 16px !important;
}

#chat-container .hQKxxG {
  bottom: 16px !important;
}

.front-open-chat-button {
  padding: 16px !important;
}

html {
  background-color: black;
  height: '100vh';
  width: '100vh';
}

h5 {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  :root {
    --border-radius-primary: 16px;
    --padding-block-horizontal: 1.5rem;
    --padding-card-secondary: 1rem;
  }
  h2 {
    font-size: 4rem;
  }

  h3 {
    white-space: pre-line;
    font-size: 2.5rem;
  }

  h5 {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  :root {
    --border-radius-primary: 1.5rem;
    --border-radius-secondary: 0.75rem;
    --padding-block-vertical-primary: 1.5rem;
    --padding-block-vertical-secondary: 2.5rem;
  }
}

@media (min-width: 1440px) {
  :root {
    --border-radius-primary: 2rem;
    --border-radius-secondary: 1rem;
    --border-radius-tertiary: 1.5rem;
    --padding-block-horizontal: 2.5rem;
    --padding-block-vertical-primary: 2.5rem;
    --padding-block-vertical-secondary: 4rem;
    --padding-card-primary: 1rem;
  }
  h2 {
    font-size: 4.5rem;
  }

  h3 {
    font-size: 3.5rem;
  }

  h4 {
    font-size: 2.5rem;
  }
}

@layer base {
  h2 {
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 110%;
    letter-spacing: 0.5px;
  }
  h3 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 110%;
  }

  h4 {
    font-weight: 700;
    font-size: 2rem;
  }

  h5 {
    font-weight: 700;
    font-size: 24px;
  }

  a {
    color: var(--link);
    text-decoration: underline;
  }
  h6 {
    font-weight: 700;
    font-size: 18px;
    text-transform: lowercase;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
