.textCommon {
  font-style: normal;
  font-size: var(--font-body-big, 1.5rem);
  line-height: 120%;
  letter-spacing: 0.24px;
}

.normalBig {
  composes: textCommon;
  font-weight: 500;
}

.normalMedium {
  composes: textCommon;
  font-weight: 500;
  font-size: var(--font-body-medium);
}

.normalSmall {
  font-size: var(--font-body-small, 1rem);
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.16px;
}

.normalTiny {
  composes: textCommon;
  font-size: var(--font-body-tiny);
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.14px;
}

.heavyBig {
  composes: textCommon;
  font-weight: 600;
}

.heavyMedium {
  composes: textCommon;
  font-weight: 600;
  font-size: var(--font-body-medium);
  letter-spacing: 0.2px;
}

.heavySmall {
  composes: textCommon;
  font-size: var(--font-body-small, 16px);
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.16px;
}

.heavyTiny {
  composes: textCommon;
  font-weight: 700;
  font-size: var(--font-body-tiny);
  line-height: 140%;
  letter-spacing: 0.14px;
}
