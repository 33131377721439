.inputContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 4rem;
  border: 2px solid var(--input-stroke-primary);
  border-radius: var(--border-radius-input-chat);
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  overflow: hidden;
}

.input {
  flex-grow: 1;
  height: inherit;
  outline: none;
  text-transform: uppercase;
}

.iconButton {
  background-color: var(--button-background-fifth) !important;
}

.activityIndicatorContainer {
  margin-left: 0rem !important;
}

.activityIndicator {
  fill: white !important;
}
