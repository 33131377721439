.container {
  position: relative;
}
.calendar {
  --day-width-mobile: 2.5rem;
  --day-width-tablet: 3.5rem;
  --day-width-desktop: 5rem;
  --border-radius: 9999px;
  width: 100%;
}

.popper,
.wrapper,
.calendar {
  width: 100%;
}

.day,
.weekday {
  width: var(--day-width-mobile);
}

.day {
  border-radius: var(--border-radius);
}

.day:hover {
  border-radius: var(--border-radius);
}

.today {
  background-color: var(--button-background-sixth);
}

@media (min-width: 768px) {
  .day,
  .weekday {
    width: var(--day-width-tablet);
  }
}

@media (min-width: 1440px) {
  .day,
  .weekday {
    width: var(--day-width-desktop);
  }
}
