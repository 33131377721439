.block {
  --padding: 2.5rem 1rem;

  display: flex;
  padding: var(--padding);

  background: var(--background-primary);
  border-radius: var(--border-radius-primary);
  min-width: 20rem;
}

.primary {
  background: var(--background-primary);
}

.fourth {
  background: var(--background-brand-fourth2);
}

.blockInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .block {
    --padding: 2.5rem 1.5rem;
  }
}

@media (min-width: 1440px) {
  .block {
    --padding: 2.5rem;
  }
}
