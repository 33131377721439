.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}
.app {
  flex: 1;
  overflow: auto;
  border-radius: var(--border-radius-primary);
}
