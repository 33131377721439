.peopleInput {
  position: relative;
}

.actions {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.actionButton {
  background-color: transparent !important;
}
