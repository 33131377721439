.actionModal {
  z-index: 50;
  align-items: flex-end;
}

.actionModalContainer {
  position: relative;
  width: 100%;
}

.actionModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-primary);
  padding: var(--padding-set-giga) var(--padding-set-medium);
  border-radius: var(--border-radius-secondary) !important;
}

.actionModalButtons {
  margin-top: 2rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
}

.actionModalButtonPrimary {
  order: 1;
}

@media (min-width: 768px) {
  .actionModal {
    align-items: center !important;
  }

  .actionModalContainer {
    max-width: 28rem;
  }

  .actionModalButtons {
    flex-direction: row;
  }
}

.actionModalCloseButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
