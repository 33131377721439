.yourPrice {
  align-self: center;
  margin-right: 1rem;
  display: none;
}

.price {
  display: inline-block;
  margin-right: 0.25rem;
}

.priceContainer {
  display: flex;
  align-items: baseline;
}

@media (min-width: 1440px) {
  .yourPrice {
    display: block;
  }
}
