.block {
  padding: var(--padding-set-small) var(--padding-block-horizontal);
  display: flex;
  align-items: center;
}

.blockInnerClassName {
  width: 100%;
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.addCampaignCodeButton {
  padding: 0rem;
}

.addCampaignCodeButtonIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.inputContainer {
  width: 100%;
  padding: 0.25rem;
}
